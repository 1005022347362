@import "../../../globals.scss";

.grid-element {
  background: #ffffff;
  padding: 1em 1em 1em 1em;
  margin: 1em 0.5em;
  position: relative;
  .actionButton {
    width: 70%;
    opacity: 0;
    margin: 1em auto;
    @media (max-width: 768px) {
      opacity: 1;
      margin: 2em auto;
      padding: 1em;
    }
  }
  &:hover {
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.18);
    text-decoration: none;
    .actionButton {
      opacity: 1;
    }
  }
  .image-product {
    display: block;
  }
  .pimage {
    height: 200px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../assets/placeholder.png");
    margin: 1em 0;
    @media (max-width: 768px) {
      height: 280px;
    }
  }

  .new-product {
    background: $primary;
    display: inline-block;
    padding: 0.2em 1em;
    font-size: 1em;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
  }
  .markers {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    width: 90%;
    margin-top: -60px;
    .marker {
      width: 60px;
      margin-left: auto;
      height: 60px;
      margin: 0 0.2em;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
    }
  }
  h3 {
    font-size: 1.2em;
    text-align: left;
    text-transform: uppercase;
    color: $primary;
  }
  .text {
    font-size: 1em;
    margin: 0;
    color: $primary;
    text-align: left;
    height: 40px;
    overflow: hidden;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 0.8em;
    margin: 0;
    color: $primary;
    text-align: left;
  }
  .energy {
    display: flex;
    font-size: 0.7em;
    span {
      background: green;
      padding: 0.3em 1em;
      display: inline-block;
      color: #fff;
    }

    &:after {
      content: " ";
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;

      border-left: 1em solid green;
    }
    &.A___ {
      span {
        background: #00ab4e;
      }
      &:after {
        border-left: 1em solid #00ab4e;
      }
    }
    &.A__ {
      span {
        background: #4fba48;
      }
      &:after {
        border-left: 1em solid #4fba48;
      }
    }
    &.A_ {
      span {
        background: #c2d82d;
      }
      &:after {
        border-left: 1em solid #c2d82d;
      }
    }
    &.A {
      span {
        background: #fff21f;
      }
      &:after {
        border-left: 1em solid #fff21f;
      }
    }
    &.B {
      span {
        background: #fcc511;
      }
      &:after {
        border-left: 1em solid #fcc511;
      }
    }
    &.C {
      span {
        background: #fa7123;
      }
      &:after {
        border-left: 1em solid #fa7123;
      }
    }
    &.D {
      span {
        background: #ed1822;
      }
      &:after {
        border-left: 1em solid #ed1822;
      }
    }
  }
}
