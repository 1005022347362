@import '../../../../globals.scss';
.footer-menu {
  color: #fff;
  width: 100%;
  z-index: 999;
  list-style-type: none;

  .menu-items {
    ul {
      display: flex;
      @media (max-width: 768px) {
        display: block;
      }
      list-style-type: none;
      li {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
          margin: 1em 0;
        }
        list-style-type: none;
        a,
        span {
          font-family: $body-font;
          font-size: 1em;
          font-weight: 400;
          text-transform: uppercase;
          color: $primary;
        }
        ul {
          display: block;
          list-style-type: none;
          li {
            width: 100%;
            display: block;
            list-style-type: none;
            a {
              font-family: $header-font;
              font-size: 1em;
              text-transform: none;
              font-weight: lighter;
              &:hover {
                text-decoration: none;
                font-family: $body-font;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}
