@import '../../../../globals.scss';
.layout-content-accordion {
  margin-bottom: 5em;

  .item {
    margin: 1em 0 0;
    border-bottom: 1px solid $secondary;
    position: relative;
    &:before {
      content: '›';
      position: absolute;
      font-size: 1.2em;
      right: 0;
    }

    p {
      color: $primary;
      font-family: $header-font;
      font-size: 1.1em;
      line-height: 1.3;
      cursor: pointer;
    }
    .content,
    .content > p {
      font-size: 1em;
      line-height: 1.6;
      color: $primary;
      font-weight: normal;
    }

    &.active {
      font-weight: bold;
      border-bottom: 1px solid $primary;
      > p {
        margin-bottom: 2em;
      }
      &:before {
        transform: rotate(90deg);
      }
    }
  }
}
