@import '../../../../globals.scss';
.content-highlight {
  margin: 310px 0 5% 0;
  text-align: left;
  position: relative;
  @media (max-width: 530px) {
    margin: 150px 0 5% 0;
  }
  &.no-image {
    margin: 5% 0;
  }
  @media (max-width: 990px) {
    margin: 210px 0 5% 0;
  }
  .top-image-wrapper {
    .top-image,
    .top-video {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 625px;
      margin-top: -400px;

      @media (max-width: 960px) {
        margin-top: -290px;
      }
      @media (max-width: 530px) {
        height: 400px;
        margin-top: -240px;
      }
    }

    .top-video {
      height: 625px;

      @media (max-width: 1200px) {
        height: 525px;
      }
      @media (max-width: 990px) {
        height: 390px;
      }
      @media (max-width: 530px) {
        margin-top: -300px;
        height: 290px;
      }
      div {
        height: 100% !important;
      }
    }
  }

  .wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10% 0 5% 0;

    .content-wrapper {
      margin-top: 5%;
      h3 {
        color: $white;
        font-weight: bold;
        font-size: 1.6em;
      }

      img {
        max-width: 100%;
      }

      .product-pack {
        text-align: center;
      }

      .content {
        margin: 1em 0;
        font-size: 1.2em;

        p {
          font-size: 1em;
          line-height: 1.6;
          color: $white;
          margin: 0 0 2em 0;
          a {
            color: $white;
            &:hover {
              text-decoration: none;
              font-weight: bold;
            }
          }
        }

        ul {
          li {
            margin: 0.2em 0;
            list-style-type: none;
            background: url('../../../assets/check.svg') left center no-repeat;
            padding-left: 2em;
            color: $white;
            p {
              margin: 0;
              line-height: 2;
            }
            span {
              line-height: 2;
            }
          }
        }
      }
    }
  }
  &.dark {
    h3,
    p,
    a,
    li {
      color: $primary !important;
    }
    li {
      background: url('../../../assets/check_black.svg') left center no-repeat !important;
    }
  }
}
