@import "../../../../globals.scss";
.product-details {
  padding: 3em 0 3em 0;
  @media (max-width: 430px) {
    padding: 5em 1em;
  }
  .product-text-section {
    text-align: left;
    color: $primary;
    padding: 7em 3em;
    @media (max-width: 800px) {
      padding: 1em;
    }

    h2 {
      font-family: $header-font;
      font-weight: 800;
      margin: 1em 0;
    }
    .reference {
      font-size: 0.9em;
      font-weight: 400;
    }
    .section-title {
      text-align: left;
      margin-bottom: 2em;
    }
    .energy {
      display: flex;
      font-size: 0.7em;
      span {
        background: green;
        padding: 0.3em 1em;
        display: inline-block;
        color: #fff;
      }
      &.A___ {
        span {
          background: #00ab4e;
        }
        &:after {
          border-left: 1em solid #00ab4e;
        }
      }
      &.A__ {
        span {
          background: #4fba48;
        }
        &:after {
          border-left: 1em solid #4fba48;
        }
      }
      &.A_ {
        span {
          background: #c2d82d;
        }
        &:after {
          border-left: 1em solid #c2d82d;
        }
      }
      &.A {
        span {
          background: #fff21f;
        }
        &:after {
          border-left: 1em solid #fff21f;
        }
      }
      &.B {
        span {
          background: #fcc511;
        }
        &:after {
          border-left: 1em solid #fcc511;
        }
      }
      &.C {
        span {
          background: #fa7123;
        }
        &:after {
          border-left: 1em solid #fa7123;
        }
      }
      &.D {
        span {
          background: #ed1822;
        }
        &:after {
          border-left: 1em solid #ed1822;
        }
      }
      &:after {
        content: " ";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 1em solid transparent;
        border-bottom: 1em solid transparent;

        border-left: 1em solid green;
      }
    }
    .product-text {
      font-size: 1em;
      // width: 70%;
      line-height: 1.5em;
      // height: 68px;
      // overflow: hidden;
      margin-bottom: 2em;

      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 576px) {
        margin: 0 auto;
      }
    }
    .contents-list-pager {
      .product-text {
        display: -webkit-box;
      }
    }

    .actionButton {
      display: inline-block;
      padding: 0.5em 4em;
      font-size: 1em;
      margin: 2em 0 0 0;
      @media (max-width: 530px) {
        padding: 1em;
        display: block;
      }
    }

    .product-items {
      width: 60%;
      li {
        list-style-type: none;
        margin: 0;
        font-size: 0.8em;
        padding: 0.5em 0;
        border-top: 1px solid #fff;
      }
    }
    ul.product-togle {
      margin: 0 0 1em 0;
      padding: 0;
      text-align: left;
      li {
        list-style-type: none;
        display: inline-block;
        a {
          color: #fff;
          display: block;
          margin: 0.5em;
          padding: 0.5em 1.5em;
          border: 1px solid #fff;
          border-radius: 2em;
          font-size: 0.8em;
          cursor: pointer;
          @media (max-width: 430px) {
            font-size: 0.7em;
            margin: 0.3em;
          }
          &.active {
            color: $secondary;
            background: #fff;
          }
          &:hover {
            color: $secondary;
            background: #fff;
          }
        }
      }
    }
  }
  .gallery-container {
    width: 100%;
    height: 500px;
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    .slick-list,
    .slick-slider {
      height: 100%;
      width: 100%;
      .slick-arrow {
        background: url("../../../assets/arrow.svg");
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
      .slick-prev,
      .slick-next {
        width: 40px;
        height: 40px;
        z-index: 99;
        &:before {
          display: none;
        }
      }
      .slick-prev {
        transform: rotate(-90deg);
        left: 0;
      }
      .slick-next {
        transform: rotate(90deg);
        right: 0;
      }
      .slick-dots {
        bottom: -35px;
        li {
          list-style-type: none;
          margin: 0;

          button:before {
            color: $primary;
            font-size: 12px;
          }
        }
      }
      .slick-track {
        height: 100%;
      }
      .slick-slide {
        width: 100%;
        .slide {
          max-width: 100%;
          height: 500px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("../../../assets/placeholder.png");
        }
      }
    }

    .markers {
      display: flex;
      position: absolute;
      justify-content: flex-end;
      width: 90%;
      margin-top: -60px;
      .marker {
        width: 60px;
        margin-left: auto;
        height: 60px;
        margin: 0 0.2em;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }
  }

  @media (max-width: 776px) {
    .gallery-container {
      height: 400px;
      margin-top: 0;
    }
    .menu-text-section {
      .menu-text {
        width: auto;
      }
      .menu-items {
        width: auto;
      }
    }
  }
}
.ril__outer {
  background: #fff !important;
}
.ril__toolbar {
  top: 0;
  right: 0;
  left: auto;
  height: 50px;
  width: 160px;
}
.custom-buying-list {
  min-width: 320px;
  .buy-options-wrap {
    padding: 2em 0;
    text-align: center;
    h3 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.6em;
      margin-bottom: 2em;
    }
    .buy-options-link {
      display: block;
      background: #c6c6c6;
      padding: 1em;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      margin: 1em 0;
      &::after {
        content: " ›";
        float: right;
        color: $primary;
      }

      &:hover {
        background: $primary;
        text-decoration: none;
        color: #fff;
        &::after {
          color: #fff;
        }
      }
    }
  }
}
