@import '../../../../globals.scss';

.breadcrumb-trail {
  color: $primary;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 1em 0;
  border-radius: 0;
  background: #fff;
  display: block;
  border-bottom: 1px solid #c6c6c6;
  @media (max-width: 800px) {
    display: none;
  }
  ul {
    margin: 0;

    text-align: left;
    li {
      list-style-type: none;
      display: inline-block;
      a,
      span {
        /* Button: */
        font-size: 0.8em;
        font-weight: normal;
        color: #8e8e93;
        margin: 0;
        padding: 1em 0;
        text-decoration: none;
        cursor: pointer;
        &.active {
          color: $primary;
          text-decoration: none;
        }
        &:hover {
          color: $primary;
          text-decoration: none;
        }
        &:after {
          content: '\203A';
          font-size: 1.4em;
          margin: 0 1em;
        }
      }
      &:last-child {
        a:after {
          content: '';
        }
      }
      span {
        cursor: default;
        font-weight: bold;
        color: $primary;
      }
    }
  }
}
