@import '../../../../globals.scss';

.content-collumns {
  margin: 3em 0;
  h4 {
    color: $primary;
    font-size: 1.4em;
    font-weight: bold;
    margin: 0;
  }
  .content {
    column-count: 2;
    column-gap: 2em;
    margin: 1em 0;
    font-size: 1em;
    @media (max-width: 600px) {
      column-count: 1;
      column-gap: 1em;
    }

    p {
      font-size: 1.2em;
      line-height: 1.6;
      color: $primary;
      margin: 0 0 1em 0;
    }
  }
}
