.list-grid-highlight {
  padding: 0 0 15% 0;
  margin: 0 0 10% 0;
  position: relative;
  width: 100%;

  .see-more {
    text-align: center;
    font-size: 0.8em;
    color: #a7c1be;
  }
}
