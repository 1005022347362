@import "../../../globals.scss";

// @keyframes zoomin {
//   from {
//     transform: scale(1);
//   }
//   to {
//     transform: scale(1.1);
//   }
// }
.header {
  height: 80%;
  background: url("../../assets/LoadingBlack.svg") no-repeat center center 80%;
  background-color: $primary;
  background-size: cover;
  background-attachment: fixed;
  transition: height 0.8s;
  position: relative;
  &.fixedPadding {
    .menu-container {
      height: 80px;
    }
  }
  @media (max-width: 530px) {
    padding-top: 80px;
  }
  * {
    transition: all 0.2s ease-in-out;
  }
  .menu-container {
    position: absolute;
    width: 100%;
  }
  .container {
    height: 100%;
  }
  @media (max-width: 600px) {
    height: 95%;
  }
  @media screen and (max-width: 770px), screen and (max-height: 700px) {
    height: 790px;
  }
  @media screen and (max-width: 430px), screen and (max-height: 600px) {
    height: 600px;
  }
  &.inner {
    height: auto;

    .slick-slider {
      display: none;
    }
    .menu-container {
      position: relative;
    }
  }

  &.fullscreen {
    height: 95%;
    margin-bottom: 10%;
    .abolute-elemtns {
      background: $primary;
      width: 40%;
      top: auto;
      padding: 1% 0;
      bottom: 0%;
    }
  }

  .logo-wrap {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    // height: 100%;
    text-align: center;
    span {
      width: 50%;
      margin: 0 auto;
      display: block;
    }
  }

  .menus {
    color: #fff;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    @media (max-width: 530px) {
      position: fixed;
    }
    &.fixed {
      position: fixed;
      background: $menu;
      // background: rgba(80,85,75,0.9);
      padding: 0;
      transition: background-color 500ms linear;
      z-index: 99;
      .wrap {
        padding: 0;
      }
      .submenu {
        display: none;
      }
      @media (max-width: 530px) {
        .right-menu {
          display: none;
        }
      }
    }
  }
  .read-safe {
    height: 100%;
    opacity: 0.67;
    background: rgba(0, 0, 0, 0.18);
  }
  .read-shadow {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.18);
  }
  .header-slide {
    background-size: cover;
    background-position: center center;
    // animation: zoomin 10s 1;
    // animation-fill-mode: forwards;
  }

  .slick-list,
  .header-wrapper,
  .header-slide {
    height: 100%;
    width: 100%;

    .slick-dots {
      z-index: 99;
      background: #fff;
      padding: 0;
      position: relative;
      overflow: auto;
      .pager {
        display: inline-block;
        height: 80px;
        width: 80px;
        background-size: cover;
        background-repeat: no-repeat;
      }
      display: flex !important;
      align-content: center;

      li {
        list-style-type: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        height: 80px;
        width: 80px;
        margin: 0 0.2em;
        button {
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 0;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
        }
        button:before {
          font-family: slick;
          font-size: 12px;
          line-height: 20px;
          content: "\2022";
          display: block;
          text-align: center;
          opacity: 0.25;
          color: #fff;
          cursor: pointer;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        &.slick-active {
          button:before {
            opacity: 1;
          }
        }
      }
    }

    .slick-track {
      height: 100%;
      > div > div {
        height: 100%;
      }
    }
    .slick-slide {
      max-width: 100%;
      height: 100%;
      color: #fff;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .abolute-elemtns {
    // padding: 0;
    // width: 100%;
    // margin-top: -30%;

    padding: 0;
    width: 100vw;
    z-index: 9999;
    top: 40%;
    position: absolute;

    @media (max-width: 600px) {
      top: 35%;
      width: 90vw;
    }
    @media (max-width: 425px) {
      top: 25%;
      width: 90vw;
    }
    .header-text-wrapper {
      max-width: 630px;
      display: flex;
      align-items: center;
      text-align: left;
      h2 {
        font-weight: bold;
        font-size: 4.2em;
        @media (max-width: 530px) {
          font-size: 3em;
        }
        color: #ffffff;
        letter-spacing: 0;
        line-height: 1;
        // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }
      h3 {
        font-weight: lighter !important;
        font-size: 1.2em;
        text-transform: uppercase;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 1.2;
        // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }
      p {
        // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        max-width: 650px;
        padding: 0;
        font-weight: normal;
        @media (max-width: 600px) {
          padding: 0;
        }
      }
      .action {
        /* Button: */
        display: inline-block;
        /* Download our Catalog: */
        font-size: 1.3em;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0;
        line-height: 1.5;
        padding: 0.5em 0;
        margin-top: 1em;
        &:hover {
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }
  .abolute-elemtns {
    display: none;
  }
  .slick-active {
    .abolute-elemtns {
      display: block;
    }
  }
  .arrows {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    text-align: left !important;
    margin-top: 1em;
    align-items: center;
    font-size: 2.8em;
    color: #fff;
    font-weight: lighter;
    background: $primary;
    padding: 0.5em 2em 0.5em 0.5em;

    @media (max-width: 530px) {
      font-size: 2em;
      padding: 0.5em 1em 0.5em 1em;
    }
    span {
      font-size: 0.4em;
      letter-spacing: 0.2em;
      // line-height: 2em;
      margin-right: 1em;
      // margin-top: 0.7em;
      font-weight: 500;
      display: inline-block;
    }
    .prev {
      display: inline-block;
      margin-right: 0.5em;
      cursor: pointer;
      opacity: 0.9;
      &::after {
        content: "‹";
      }
      &:hover {
        opacity: 1;
      }
    }
    .next {
      display: inline-block;
      cursor: pointer;
      opacity: 0.7;
      &::after {
        content: "›";
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.home {
  .header {
    height: 100%;
  }
}
