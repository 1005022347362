.content-media {
  margin: 6em 0;
  position: relative;
  .media-wrap {
    .imageDiv {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 300px;
    }
  }
}
