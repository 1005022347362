@import '../../../../globals.scss';

.submenu {
  padding: 0;
  margin: 0;
  display: block;
  display: flex;
  transition: none;

  @media (max-width: 530px) {
    display: none;
  }
  .search {
    height: 60px;
    width: 80%;
    @media (max-width: 768px) {
      width: 70%;
    }
  }
  .menu {
    height: 60px;
    width: 20%;
    background: $secondary;
    @media (max-width: 768px) {
      width: 30%;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      text-align: right;
      display: inline-block;

      a {
        color: $primary;
        font-size: 0.7em;
        text-transform: uppercase;
        padding: 0em 0.7em;
        -webkit-font-smoothing: subpixel-antialiased;
        .active {
          font-weight: bold;
        }
        &:hover {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
}
