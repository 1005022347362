@import "./globals.scss";

@font-face {
  font-family: "Metric-web";
  src: url("./app/assets/MetricMedium/MetricMedium.eot");
  src: url("./app/assets/MetricMedium/MetricMedium.eot?#iefix")
      format("embedded-opentype"),
    url("./app/assets/MetricMedium/MetricMedium.woff") format("woff"),
    url("./app/assets/MetricMedium/MetricMedium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Metric-web";
  src: url("./app/assets/Metric-Bold/Metric-Bold.eot");
  src: url("./app/assets/Metric-Bold/Metric-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./app/assets/Metric-Bold/Metric-Bold.woff") format("woff"),
    url("./app/assets/Metric-Bold/Metric-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Metric-web";
  src: url("./app/assets/Metric-Light/Metric-Light.eot");
  src: url("./app/assets/Metric-Light/Metric-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./app/assets/Metric-Light/Metric-Light.woff") format("woff"),
    url("./app/assets/Metric-Light/Metric-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

body,
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: $body-font !important;
  font-weight: 300 !important;
  height: 100%;
  min-height: 100%;

  /* word-break: break-word; */
}

// * {
//   transition: all 0.2s ease-in-out;
// }
.body-container,
#app,
#root {
  height: 100%;
  min-height: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font !important;
  font-weight: 500;
  letter-spacing: -0.01em;
}

@media (max-width: 776px) {
  .row > div {
    margin-bottom: 2em;
  }
}

@media (max-width: 600px) {
  body,
  html {
    font-size: 1em;
  }
}

.clearfix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
/* custom */
@media (min-width: 768px) {
  .container {
    max-width: 1025px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1240px;
  }
}
@media (max-width: 992px) {
  body {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}
@media (max-width: 600px) {
  /* .row {
    margin-right: -8%;
    margin-left: -8%;
  }
  .row > div {
    margin-right: 8%;
    margin-left: 8%;
  } */
}
.slick-slider {
  height: 100%;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

//Share this buttons

.st-sticky-share-buttons {
  z-index: 99 !important;
  .st-btn {
    border: none !important;
    background: transparent !important;
    img {
      filter: black !important;
      filter: grayscale(1) !important;
    }
  }
  @media (max-width: 500px) {
    display: none !important;
  }
}
