.search-modal {
  width: 100%;
  min-width: 380px;
  padding: 3em 1em;
  position: absolute;
  background: rgba(26, 25, 25, 0.96);
  right: 0;
  max-width: 100%;
  top: 0;
  h2 {
    color: #fff;
    font-size: 1.4em;
    margin: 0 0 1em 0;
    font-weight: lighter !important;
  }

  input {
    background: transparent;
    border: none;
    font-size: 2em;
    border-bottom: none;
    border-radius: 0;
    color: #fff;

    &:focus {
      background: transparent !important;
      color: #fff;
      border: none;
      border-bottom: 2px solid #fff;
      outline: 0 !important;
    }
  }
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-webkit-autofill {
    box-shadow: none;
    background-color: transparent !important;
  }
  .content {
    h6 {
      color: #fff;
      font-size: 2em;
    }
    li {
      color: #fff;
      list-style-type: none;
      padding: 2em 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      vertical-align: middle;
      img {
        max-width: 200px;
        margin: 0 1em 0 0;
      }
    }
    a {
      margin: 0.5em 0;
    }
  }
  .loadmore-wrap {
    text-align: center;
    .loadmore {
      display: inline-block;
      font-size: 1em;
      color: #50554b;
      background: #fff;
      padding: 1em 2em;
      border-radius: 1.5em;
      letter-spacing: 0;
      line-height: 1;
      box-shadow: 1px 0px 9px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      i {
        margin-left: 0.5em;

        font-size: 0.7em;
      }
    }
  }
}

.search-overlay {
  background: transparent;
  button {
    position: fixed;
    right: 2em;
    zoom: 1.4;
    svg {
      fill: #fff;
    }
  }
}

.search-wrapp {
  width: 80%;
}
.search-menu {
  display: flex;
  background: #fff;

  input {
    background: transparent;
    border: none;
    font-size: 1.3em;
    line-height: 60px;
    height: 60px;
    border-bottom: none;
    border-radius: 0;
    color: #16181e;
    outline-width: 0;
    outline: 0 !important;
    width: 90%;

    &:focus,
    &:active {
      background: transparent !important;
      color: #16181e;
      border: none;
      border-bottom: none;
      outline: 0 !important;
      border-color: transparent;
      box-shadow: none;
      outline-width: 0;
    }
  }

  .search-zoom {
    width: 10%;
    cursor: pointer;
    margin-right: 2em;
    background: url('../../assets/zoom.svg') right center no-repeat #fff;
  }
  &:hover {
    .search-zoom {
      opacity: 0.6;
    }
  }
}
