.grid-text-slideshow {
  padding: 4em 0; }
  .grid-text-slideshow.first {
    padding: 0;
    position: absolute;
    width: 100%;
    display: flex;
    bottom: 0; }
    @media screen and (max-width: 770px), screen and (max-height: 700px) {
      .grid-text-slideshow.first {
        bottom: auto;
        top: 740px; } }
    @media screen and (max-width: 430px), screen and (max-height: 600px) {
      .grid-text-slideshow.first {
        bottom: auto;
        top: 553px; } }
    .grid-text-slideshow.first .slick-slider {
      width: 100%; }
    .grid-text-slideshow.first .summary {
      color: #fff;
      font-size: 1.1em;
      display: inline-block;
      width: 62%;
      text-align: right;
      padding-right: 2%;
      padding-left: 2%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-width: 768px) {
        .grid-text-slideshow.first .summary {
          width: 72%; } }
    .grid-text-slideshow.first .button {
      width: 38%;
      display: inline-block; }
      @media (max-width: 768px) {
        .grid-text-slideshow.first .button {
          width: 28%; } }
      .grid-text-slideshow.first .button a {
        background: #12b6c0;
        color: #fff;
        display: inline-block;
        padding: 1em 3em; }
