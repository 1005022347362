@import '../../../globals.scss';
.footer-wrapper {
  .footer {
    padding: 3em;
    background: $footer;
    @media (max-width: 768px) {
      text-align: center;
    }
    .logo {
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      height: 100px;
      max-width: 90px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .sub-menu-wrapper {
      border-right: 1px solid #979797;
      @media (max-width: 768px) {
        border-right: none;
      }
    }

    .content-wrapper {
      font-family: $header-font;
      font-size: 1em;
      text-transform: none;
      font-weight: lighter;
      padding: 0 2em;
      color: $primary;
      a {
        color: $primary;
        text-decoration: underline;
      }
      strong {
        font-family: $body-font;
      }
    }
  }
  .rights {
    text-align: center;
    margin: 1em 0;
    font-size: 0.8em;
  }
}
