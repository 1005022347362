@import "../../../../globals.scss";
.contents-list-pager {
  margin: 12% 0;
  .content-wrapper {
    background: #f3f3f3;
    .slide {
      padding: 2em 0;
      @media (max-width: 530px) {
        text-align: left;
      }
      .content {
        color: $primary;

        @media (max-width: 767px) {
          padding: 0;
          float: none;
          width: 100%;
        }
        @media (max-width: 572px) {
        }
        h3 {
          margin-bottom: 1m;
          color: $primary;
          font-weight: bold;
          font-size: 2.4em;
        }
        .position {
          color: $primary;
        }
        p {
          color: $primary;
          font-size: 1em;
          line-height: 1.5;
        }
      }
      .image {
        width: 40%;
        margin-left: 5%;
        float: left;
        padding: 3em 0;
        position: relative;
        overflow: hidden;
        min-height: 300px;
        @media (max-width: 767px) {
          float: none;
          width: 100%;
          padding: 0;
          margin: 0;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }

  .chars {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 32%;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.8em 0.2em;
      border-bottom: 1px solid #00000020;
      @media (max-width: 1000px) {
        width: 48%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
      .title {
        font-size: 0.8em;
        color: #00000080;
      }
      .value {
        font-size: 0.8em;
        text-align: right;
        font-weight: bold;
      }
    }
  }
  .pager {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    margin: 0;
    .item {
      text-align: center;
      font-weight: bold;
      font-size: 1em;
      line-height: 1.1;
      padding: 1em 2em;
      cursor: pointer;
      @media (max-width: 570px) {
        padding: 1em;
      }
      @media (max-width: 380px) {
        padding: 0;
      }
      p {
        margin: 0;
      }
      .position {
        color: $primary;
        margin-top: 0.4em;
        font-size: 0.84em;
        font-weight: normal;
      }
      .image {
        max-width: 100%;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        margin-bottom: 0.5em;
        @media (max-width: 380px) {
          height: 50px;
          width: 50px;
        }
      }

      &:hover,
      &.active {
        color: $primary;
        border-bottom: 2px solid $primary;
        .image {
          path {
            fill: $primary;
          }
        }
      }
      &.active {
        opacity: 0.8;
      }
    }
  }
  .manuais-list {
    margin-top: 2em;
    ul {
      list-style-type: none;
      li {
        max-width: auto;
        margin: 0 auto;
        a {
          padding: 2em 3em 2em 1em;
          color: #838383;
          display: block;
          text-transform: capitalize;
          font-weight: 400;
          background: url("../../../assets/icon_download.svg") no-repeat right
            center;
          word-break: break-all;
          &:hover {
            text-decoration: none;
            font-weight: bold;
          }
          span {
            display: table-cell;
            vertical-align: middle;
            word-break: break-all;
          }
          .pdf {
            background: url("../../../assets/icon_pdf.svg") no-repeat left
              center;
            width: 40px;
            height: 40px;
          }
        }
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }
}
