.horizontal-image-background .background-image {
  min-height: 570px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.horizontal-image-background .content {
  padding: 4em;
  margin: 4em 0;
  background: #fff; }
  @media (max-width: 992px) {
    .horizontal-image-background .content {
      padding: 2em; } }
  .horizontal-image-background .content h5 {
    color: #50554b;
    font-size: 2.5em; }
  .horizontal-image-background .content p {
    font-size: 1em;
    line-height: 1.6;
    color: #50554b;
    margin-bottom: 2em; }
