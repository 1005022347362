@import "../../../../globals.scss";
.manuais {
  padding: 0 0 4em 0;
  .manuais-input {
    input {
      display: block;
      border: none;
      background: url("../../../assets/zoom.svg") no-repeat 95% center #fff;
      padding: 1.5em;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
    .input-group {
      position: relative;

      .buttonSearch {
        content: "";
        display: block;
        width: 80px;
        height: 45px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }
  .manuais-list {
    margin-top: 2em;
    ul {
      list-style-type: none;
      li {
        a {
          padding: 2em 3em 2em 1em;
          color: #838383;
          display: block;
          text-transform: capitalize;
          font-weight: 400;
          background: url("../../../assets/icon_download.svg") no-repeat right
            center;
          word-break: break-all;
          &:hover {
            text-decoration: none;
            font-weight: bold;
          }
          span {
            display: table-cell;
            vertical-align: middle;
            word-break: break-all;
          }
          .pdf {
            background: url("../../../assets/icon_pdf.svg") no-repeat left
              center;
            width: 40px;
            height: 40px;
          }
        }
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }
}
