.horizontal-text-block-background {
  margin: 5em 0;

  .color-line {
    height: 4px;
    width: 45%;
    background: #12b6c0;
    position: absolute;
    left: -40%;
    top: 20%;
    z-index: 9;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .color-line-right {
    height: 4px;
    width: 45%;
    background: #12b6c0;
    position: absolute;
    right: -36%;
    top: 20%;
    z-index: 9;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .image {
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin: 0 auto; // height:550px;
    // background: url('../../assets/placeholder.png') no-repeat;
    background-size: cover;
    min-height: 420px;
    display: inline-block;
    @media (max-width: 768px) {
      float: none;
    }
    @media (max-width: 425px) {
      min-height: auto;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .noimage {
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin: 0 auto; // height:550px;
    background: url('../../../../assets/placeholder.png') no-repeat;
    background-size: cover;
    min-height: 420px;
    display: inline-block;
  }
  .content {
    padding: 0 4em;
    @media (max-width: 992px) {
      padding: 2em;
    }
    h5 {
      color: #18286c;
      font-weight: bold;
      font-size: 2.6em;
      @media (max-width: 425px) {
        font-size: 2em;
      }
    }
    h6 {
      text-transform: uppercase;
      font-weight: bold;
      color: #18286c;
      font-size: 0.8em;
      margin-bottom: 5em;
    }

    p {
      font-size: 1em;
      line-height: 1.6;
      color: #18286c;
      margin: 0 0 2em 0;
    }

    ul {
      list-style-type: none;
      padding-right: 10%;
      li {
        list-style-type: 0;
        margin-bottom: 0.8em;
        p {
          margin: 0;
          color: #12b6c0;
        }
        strong {
          display: block;
        }
      }
    }
  }
  &.color {
    color: #fff;
    background: #0a4048 no-repeat;
    padding: 5% 0;
    .image {
      margin-top: -20%;
      @media (max-width: 600px) {
        margin-top: 0%;
      }
    }
    .content {
      p {
        color: rgba(255, 255, 255, 0.8);
      }
      h5,
      h6,
      a,
      strong {
        color: #fff;
      }
    }
  }

  &.odd {
    .color-line {
      height: 4px;
      width: 45%;
      background: #12b6c0;
      position: absolute;
      z-index: 2;
      right: -40%;
      left: auto;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  &.even {
    .image {
      @media (max-width: 768px) {
        float: none;
      }
    }
  }

  &.first {
    .content {
      margin-top: -10%;
    }
    .half-background {
      background: #0f4048;
      width: 53%;
      position: absolute;
      right: 0;
      margin-top: -10%;
      padding: 10%;
      color: #fff;
      .content-wrap {
        max-width: 480px;
      }
      .color-line {
        top: 50%;
        left: -33%;
      }
      h5 {
        font-size: 2.3em;
        margin-bottom: 0.5em;
      }
    }
  }
}
.home {
  .horizontal-text-block-background.color {
    background: url('../../../../assets/logo_white.svg') #0a4048 no-repeat;
    margin-top: -7%;
    background-size: 70%;
    background-position: right;
    padding: 15% 0;
    @media (max-width: 600px) {
      background: #0a4048;
    }
  }
}
