@import "../../../../globals.scss";
.menus {
  color: #fff;
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: $menu;

  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &.fixed {
    position: fixed;

    // background: rgba(80,85,75,0.9);
    padding: 0;
    transition: background-color 300ms linear;
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.3);

    z-index: 991;
    .wrap {
      padding: 0;
      margin: 0;
      height: 60px;
      .logo {
        a {
          width: 150px;
          height: 80px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          background-image: url("../../../assets/logo.svg");
          &:hover,
          &.active {
            color: #41566d;
            border-bottom: none;
            text-decoration: none;
          }
        }
      }

      .menu-items {
        li {
          margin: 0;
          @media (max-width: 768px) {
            margin: 0;
            font-size: 0.9em;
          }

          &:hover,
          &.active {
            .submenu_1 {
              // display: block;
              opacity: 1;
              z-index: 9;
            }
          }
        }
        span {
          cursor: default;
        }
        .submenu_1 {
          // opacity: 0;
          // display: none;
          margin-top: 0;
          z-index: -1;
          a,
          span {
            display: block;
            padding: 1em 0 !important;
            text-align: left;
            margin: 0.3em 0;
            border-radius: 0;
            border-bottom: 1px solid #90959f;
            font-size: 0.9em;
            font-family: $body-font;
            line-height: 1em;
            font-weight: bold;
            text-align: left;
            color: #90959f;
          }
        }
      }
    }
  }
}
.wrap-mobile .bm-burger-bars {
  background: #50544b !important;
}
.bm-overlay {
  left: 0;
}
.wrap {
  color: #fff;
  text-align: left;
  list-style: none;
  margin: 0 0 0 0;
  padding: 0 0 0;
  display: block;
  height: 80px;
  @media (max-width: 530px) {
    display: none;
  }

  .menu-items {
    display: block;
    li {
      float: right;
      display: inline-block;
      text-align: left;
      margin: 0 0.3em;
      @media (max-width: 768px) {
        margin: 0;
      }
      a,
      span {
        font-size: 0.9em;
        display: inline-block;

        padding: 0 1.4em;
        line-height: 79px;
        margin-top: 0;
        letter-spacing: 0.05em;
        -webkit-font-smoothing: subpixel-antialiased;
        color: #fff;
        text-decoration: none;
        font-weight: 400;
        letter-spacing: -0.01em;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
        &:hover,
        &.active {
          margin-top: 0px;
          line-height: 79px;
          text-decoration: none;
          border-bottom: 1px solid #fff;
        }

        @media (max-width: 1024px) {
          padding: 5px 8px;
        }
        @media (max-width: 980px) {
          padding: 5px 8px;
          font-size: 0.9em;
        }
      }
      // &:first-child {
      //   a {
      //     padding: 5px 0 0 15px;
      //     &:hover,
      //     &.active {
      //       padding: 5px 15px;
      //     }
      //   }
      // }
      &.active {
        span {
          border-bottom: 1px solid #fff;
          font-weight: bold;
          opacity: 0.4;
        }
        // &:hover {
        .submenu_1 {
          // display: block;
          opacity: 1;
          z-index: 0;
        }
        // }
      }
      &.notactive {
        .submenu_1 {
          display: none;
        }
      }
    }
    .submenu_1 {
      opacity: 0;
      z-index: -1;

      // display: none;
      position: absolute;
      margin-left: 0;
      width: 100%;
      background: #e5e5e5;
      left: 0;
      padding: 2em 0;
      font-size: 1.1em;

      li {
        display: inline-block;
        margin: 0;
        float: none;
        width: 33%;
        text-align: left;

        span,
        a {
          display: block;
          padding: 1em 0 !important;
          text-align: left;
          margin: 0.3em 0;
          border-radius: 0;
          border-bottom: 1px solid #90959f;
          font-size: 0.9em;
          font-family: $body-font;
          line-height: 1em;
          font-weight: bold;
          text-align: left;
          color: #90959f;
          &:hover,
          &.active {
            text-decoration: none;
            color: #16181e;
            border-bottom: none;
          }
        }

        &:hover,
        &.activeSub {
          > a,
          > span {
            text-decoration: none;
            color: #16181e !important;
            border-bottom: none !important;
          }
          .submenu_2 {
            // height: calc(100vh - 173px);
            overflow: auto;
            /* width */
            &::-webkit-scrollbar {
              width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888;
              border-radius: 5px;
              padding-right: 2px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
            > .container {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              column-gap: 1em;
            }
          }
          .submenu_2,
          .submenu_3 {
            display: flex;
            align-items: flex-start;
            column-gap: 1em;
          }
        }
        &.notaParent {
          margin: 0 4em 0 0;
          width: auto;
          span,
          a {
            border-bottom: none !important;
          }
        }
      }

      .submenu_2 {
        font-weight: bold;
        display: none;
        position: absolute;
        background: #e5e5e5;
        width: 100%;
        left: 0;
        padding: 2em 0;
        &:hover {
          display: flex;
          align-items: flex-start;
          column-gap: 1em;
        }
        .container {
          li {
            margin: 1em 1em 0 0;
            width: 23%;
            a,
            span {
              color: $primary;
              padding: 0 !important;
              font-weight: normal;
              border-bottom: none;
            }
            &.notaParent {
              margin: 0.3em 0;
              span,
              a {
                border-bottom: none;
              }
            }
          }
        }
      }
      .submenu_3 {
        display: none;
        font-weight: normal;
        &:hover {
          display: block;
        }
        .container {
          margin: 0;
          padding: 0;
          display: block;
          li {
            width: 100%;
            a,
            span {
              color: #90959f;
              font-weight: normal;
              font-family: $header-font;
              margin: 0;
              padding: 0 !important;
              border-bottom: none;
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    display: block;
    float: left;
    a {
      display: block;
      width: 160px;
      height: 80px;

      background: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0;
      padding: 0;

      img {
        height: 50px;
        @media (max-width: 430px) {
          height: 30px;
        }
      }
      h5 {
        font-size: 1.1em;
        color: #ffffff;
        margin: 1em 0 0.5em 0;
        padding: 0;
        @media (max-width: 600px) {
          margin: 0.5em 0 0em 0;
        }
        @media (max-width: 430px) {
          font-size: 0.8em;
        }
      }
      p {
        font-size: 0.8em;
        color: #fff;
        margin: 0;
        padding: 0;
        @media (max-width: 430px) {
          font-size: 0.7em;
        }
      }
    }
  }
}
