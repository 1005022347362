@import '../../../../globals.scss';
.alternate-content {
  padding: 2em 0;

  .image {
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin: 0 auto; // height:550px;
    // background: url('../../assets/placeholder.png') no-repeat;
    background-size: cover;
    min-height: 420px;
    display: inline-block;

    @media (max-width: 425px) {
      min-height: auto;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .noimage {
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin: 0 auto; // height:550px;
    // background: url('../../../../assets/placeholder.png') no-repeat;
    background-size: cover;
    min-height: 420px;
    display: inline-block;
  }
  .content {
    h5 {
      color: $primary;
      font-weight: bold;
      font-size: 1.4em;
      @media (max-width: 425px) {
        font-size: 2em;
      }
    }

    p {
      font-size: 1.2em;
      line-height: 1.6;
      color: $primary;
      margin: 0 0 2em 0;
    }
  }
}
