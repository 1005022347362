@import '../../../globals.scss';
.grid-element-hover {
  position: relative;
  .image {
    min-height: 300px;
    margin: 1em 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .content {
    text-align: center;
    position: absolute;
    margin-top: -73px;
    width: 100%;
    .blue-line {
      background: #a7c1be;
      width: 50%;
      height: 0.5em;
      display: inline-block;
    }
    h6 {
      background: #fff;
      display: inline-block;
      padding: 1em 2em;
      font-size: 1.2em !important;
      font-weight: bold;
      width: 90%;
      color: #212529;
      margin: 0;
      margin-top: -0.4em;
    }
  }

  &.first .image {
    height: 400px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 5%;
  }

  &.first .content {
    text-align: center;
    position: absolute;
    margin-top: -103px;
    width: 100%;
    h6 {
      width: 70%;
    }
  }
}
