.admin-modal {
  width: 500px;
  height: 100%;
  padding: 0;
  max-width: none;
  position: fixed;
  left: 0;
  div {
    height: 100%;
  }
  &.hide {
    margin-left: -500px;
  }
}

.admin-content {
  border: none;
}

.admin-overlay {
  background: none;
  padding: 0;
  width: 500px;
  &.hide {
    margin-left: -500px;
  }
}

.admin-sidebar {
  .admin-sidebar-icon {
    background: #312f2f;
    cursor: pointer;
    padding: 10px;
    position: fixed;
    width: 40px;
    height: 40px;
    z-index: 1001;
    top: 8em;
    left: 0;
    border-radius: 0 5px 5px 0;
    box-shadow: 10px 10px 18px -16px rgba(0, 0, 0, 0.75);
    span {
      background: url('../../assets/edit.svg') no-repeat center center;
      background-size: contain;
      display: block;
      width: 20px;
      height: 20px;
    }
    &.open {
      left: 500px;
    }
  }
}
