@import '../../../../globals.scss';
.center-text {
  padding: 3% 0 3% 0;
  text-align: center;
  &.colored {
    padding-top: 8%;
  }
  @media (max-width: 530px) {
    padding: 10% 0;
  }

  h3 {
    color: $primary;
    text-align: center;
    font-weight: bold;
    font-size: 3.3em;
    @media (max-width: 530px) {
      font-size: 2.3em;
    }
  }
  .line-spacer {
    display: inline-block;
    width: 30%;
    border-bottom: 1px solid #90959f;
  }
  h4 {
    color: $primary;
    text-align: center;
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 1.5em;
    margin-bottom: 0;
  }
  .content {
    margin: 1em 0;
    font-size: 1em;
    @media (max-width: 600px) {
      column-count: 1;
      column-gap: 1em;
    }
    @media (max-width: 1024px) {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    p {
      font-size: 1.2em;
      line-height: 1.6;
      color: $primary;
      margin: 0 0 0 0;
      text-align: center;
    }
    a {
      color: #a7c1be;
    }
  }
  &.left,
  &.right {
    h4,
    h3,
    p,
    .content {
      text-align: left;
    }
    .line-spacer {
      display: none;
    }
  }
}
