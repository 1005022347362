.readmore {
  /* Button: */
  font-size: 1em;
  color: #12b6c0;
  font-weight: bold;
  margin: 18px 0;
  padding: 0.5em 0;
  text-decoration: none;
}
.readmore i {
  font-size: 0.6em;
}
.readmore:hover {
  color: #0a4048;
  text-decoration: none;
}
