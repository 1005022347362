@import '../../../globals.scss';
.actionButton {
  background-image: linear-gradient(143deg, #16181e 0%, #464a53 100%);
  color: #fff !important;
  display: block;
  text-align: center;
  margin: 0.8em -1em;
  cursor: pointer;
  font-size: 0.94em;
  padding: 0.6em 0;
  border-radius: 2em;
  text-decoration: none;
  &::after {
    font-size: 1.1em;
    content: ' ›';
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    background-image: linear-gradient(143deg, #16181e 0%, #16181e 100%);
  }
}
