@import "../../../../globals.scss";
.list-grid {
  margin: 3em 0;
  position: relative;
  // &:before {
  //   content: '';
  //   height: 2%;
  //   background: red;
  // }

  .line {
    border-bottom: 1px solid $secondary;
  }

  .row {
    margin: 0 0 5em 0;
    position: relative;
    &:first-child {
      background: #fff;
      // border-top: #fff 4em solid;
    }
    @media (max-width: 500px) {
      margin: 0;
    }
  }
  .white-line {
    height: 8px;
    width: 100%;
    bottom: -5px;
    position: absolute;
    background: #fff;
  }
  .cat-section {
    h3 {
      font-weight: bold;
      font-size: 2.2em;
      margin: 1em 0;
    }
    .image-cat-holder {
      height: 300px;
      background: #000;
      background-position: center;
      background-size: cover;
      padding: 5%;
      margin-bottom: 5%;
      h4 {
        font-weight: bold;
        font-size: 2.2em;
        margin: 1em 0;
        color: #fff;
        width: 70%;
      }
      &.noBackground {
        height: auto;
        padding: 0%;
      }
    }
  }
}
.noResults {
  padding: 10vh;
  height: 70vh;
}
