.section-title {
  font-size: 3em;
  font-weight: bold;
  color: #0d3c47;
  letter-spacing: -2;
  text-align: center;
  margin-bottom: 0.8em;
  text-transform: uppercase; }
  .section-title strong {
    letter-spacing: 0; }
