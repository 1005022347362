@import '../../../../globals.scss';
.wrap-mobile {
  color: #fff;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;

  @media (max-width: 530px) {
    display: block;
  }

  .bm-burger-bars {
    background: #fff !important;
  }

  &.open {
    .bm-burger-button {
      display: none !important;
    }
  }
  &.closed .cross {
    display: none !important;
  }

  .logo a {
    display: inline-block;
    width: 120px;
    height: 75px;
    background: transparent;
    background-image: url('../../../assets/logo_white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 0 0;
    padding: 0;

    &:hover {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      // @media (max-width: 700px) {
      //   background-image: url('/images/logoMobile.png');
      //   height: 200px;
      // }
    }
    // @media (max-width: 800px) {
    //   margin: 0;
    // }
    // @media (max-width: 380px) {
    //   width: 220px;
    // }
    // @media (max-width: 768px) {
    //   height: 45px;
    //   width: 220px;
    // }
  }

  //
  // Burger menu custom styles
  //
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 36px;
    top: 28px;
  }

  .right .bm-burger-button {
    left: initial;
    right: 36px;
    cursor: pointer;
  }

  .cross {
    font-size: 3.2em;
    position: absolute;
    right: 30px;
    top: 0;
    transform: rotate(45deg);
  }

  .bm-burger-bars {
    background: $secondary;
    cursor: pointer;
    height: 12% !important;
  }

  .bm-morph-shape {
    fill: #fff;
  }

  .bm-menu {
    background: $primary;
    border-top: 1px solid #464a53;

    &:hover,
    &:focus {
      box-shadow: none;
    }
    .top {
      box-shadow: none;
      text-shadow: none;
      padding-bottom: 15%;
      -webkit-tap-highlight-color: none;
      outline: none;
      ul {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        li {
          list-style-type: none;
        }

        a,
        span {
          color: #fff;
          font-size: 1.3em;
          text-transform: uppercase;
          text-decoration: none;
          display: block;
          cursor: pointer;
          border-bottom: 1px solid #464a53;
          font-family: $body-font;
          font-weight: 500;
          &:hover,
          &:focus {
            color: $primary;
            background: $secondary;
          }
        }

        .submenu_1,
        .submenu_2 {
          display: none;
        }

        .submenu_1 {
          background: $submenu1;
          li {
            > span,
            > a {
              background: #717171;
              color: #fff;
            }
          }
          > .notaParent {
            > span,
            > a {
              background: $secondary;
              color: $primary;
            }
          }
        }
        .submenu_2 {
          li {
            > span,
            > a {
              background: $secondary;
              color: $primary;
            }
          }
        }
        .submenu_3 {
          li {
            > span,
            > a {
              background: #fff;
              color: #90959f;
              font-weight: normal;
            }
          }
        }

        .activeMain {
          > span {
            color: $secondary;
            background: rgb(65, 65, 65);
          }

          .submenu_1 {
            display: block;
          }
          .activeSub {
            background: #fff;
            ul {
              display: block;
            }
          }
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      .search-wrapp {
        width: 100%;
      }
      .search {
        input {
          padding: 1.6em;
          border: 0;
          margin: 0;
        }
      }
      .lang {
        margin: 0;
        padding: 0.5em;
        background: $footer;
        padding-left: 0 !important;
        padding-right: 0 !important;
        li {
          width: 33%;
          padding: 0.5em 0;
          &::before {
            display: none;
          }

          a {
            border-right: 1px solid #90959f;
            display: block;
            line-height: 1;
            padding: 0;
          }
          &:last-child {
            a {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .bm-overlay,
  .bm-menu-wrap {
    top: 80px;
    height: calc(100% - 80px) !important;
  }
  .bm-menu {
    scroll-behavior: smooth;
  }

  .bm-item-list {
    a,
    span {
      padding: 1.6em;
      text-align: center;

      span {
        margin-left: 10px;
        font-weight: 700;
      }
    }
  }
  //
  // Mixins
  //
  .menu-1 {
    .bm-cross {
      background: #bdc3c7;
    }

    .bm-menu {
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
  }

  .menu-2 {
    .bm-cross {
      background: #999;
    }

    .bm-menu {
      padding: 3.4em 1em 0;
    }

    a {
      padding: 1em;
    }

    i {
      font-size: 1.7em;
      vertical-align: middle;
      color: #282a35;
    }
  }

  .menu-3 {
    .bm-cross {
      background: #888;
    }

    .bm-menu {
      padding: 2em 1em;
      font-size: 1.15em;
    }

    i {
      opacity: 0.5;
    }

    span {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.75em;
    }
  }

  .menu-4 {
    .bm-cross {
      background: #888;
    }

    h2 {
      margin: 0 auto;
      padding: 2em 1em;
      color: rgba(0, 0, 0, 0.4);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

      i {
        margin-left: 0.2em;
      }

      span {
        font-size: 1.6em;
        font-weight: 700;
      }
    }

    a {
      padding: 1em;
      text-transform: uppercase;
      transition: background 0.3s, box-shadow 0.3s;
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

      span {
        letter-spacing: 1px;
        font-weight: 400;
      }

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
        color: #b8b7ad;
      }
    }
  }

  // #slide,
  // #stack,
  // #reveal {
  //   .menu-1;
  // }

  // #bubble,
  // #push {
  //   .menu-2;
  // }

  // #elastic {
  //   .menu-3;
  // }

  // #scaleDown,
  // #scaleRotate,
  // #pushRotate,
  // #fallDown {
  //   .menu-4;
  // }
}
