.categories {
  color: #489ed8;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 1em 0 0;
  display: block;
  margin: 0;
  text-align: center; }
  @media (max-width: 800px) {
    .categories {
      display: none; } }
  .categories li {
    list-style-type: none;
    display: inline-block; }
    .categories li a {
      /* Button: */
      background-color: #fff;
      font-size: 0.8em;
      color: #489ed8;
      border: 2px solid #489ed8;
      font-weight: bold;
      margin: 1em;
      padding: 1em;
      text-decoration: none;
      cursor: pointer; }
      .categories li a.active {
        color: #fff;
        border: 2px solid #0d3c47;
        background: #0d3c47;
        text-decoration: none; }
      .categories li a:hover {
        color: #fff;
        border: 2px solid #0d3c47;
        background: #0d3c47;
        text-decoration: none; }
