.category-highlight {
  margin: 3em 0; }
  .category-highlight .highlight {
    background: #50554b;
    padding: 2em; }
    .category-highlight .highlight h5 {
      font-family: 'Roboto' !important;
      font-weight: 100 !important;
      color: #fff;
      font-size: 2em;
      margin-bottom: 0.8em; }
    .category-highlight .highlight p {
      color: #fff;
      font-size: 0.8em;
      line-height: 1.5; }
  .category-highlight .content div {
    color: #50554b;
    font-size: 1em;
    padding: 0 3em; }
    .category-highlight .content div h4 {
      color: #50554b;
      font-family: 'Roboto' !important;
      font-weight: 100 !important;
      font-size: 2em;
      padding: 1em 0; }
