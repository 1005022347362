@import "../../../../globals.scss";
.contact {
  // background:#fff;
  padding: 2em;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 768px) {
    .row > div {
      margin-bottom: 1em;
    }
  }

  .dropZoneContainer {
    position: relative;
    .FileUpload {
      width: 100%;
      height: 71px;
    }

    .dropZoneOverlay {
      border: dotted 1px;
      background: #fff;
      position: absolute;
      top: 0px;
      text-align: center;
      width: 100%;
      padding: 1.4em;
    }

    .FileUpload {
      opacity: 0;
      position: relative;
      z-index: 1;
    }
    &.valid {
      .dropZoneOverlay {
        background: rgba(107, 197, 87, 0.515);
      }
    }
  }

  @media (max-width: 430px) {
    padding: 3em 1em;
  }
  .section-title {
    color: $primary;
    // color:#8ECA80;
  }
  .map-container {
    width: 100%;
    height: 100%;
    min-height: 450px;
    background: #ddd;
    border: none;
  }
  .contactus-container {
    text-align: left;
    width: 70%;
    margin: 0 auto;
    @media (max-width: 776px) {
      width: auto;
    }
    h2 {
      text-align: center;
    }
    label {
      display: block;
      margin: 0.3em 0;
      padding: 0;
      line-height: 1;
      font-weight: bold;
      font-size: 1.1em;
    }
    input,
    textarea {
      margin: 0.3em 0 1em 0;
      padding: 1.2em;
      font-size: 1.2em;
      /* Input: */
      background: #fff;
      // background: #8ECA80;
      // color: #fff;
      color: $primary;
      border: none;
      border-radius: 0;
      &::placeholder {
        color: $secondary;
        // color:#fff;
      }
      &.invalid {
        border: 1px solid red;
      }
    }
    .errorMessage {
      text-align: right;
    }
    .options {
      margin: 1em 0;
      .col-6,
      .col-12 {
        padding: 0 0 0 0.3em;
      }
    }
    textarea {
      height: 200px;
    }

    .customRadio {
      input {
        margin: 0;
        padding: 0;
      }
      label {
        line-height: 1.6;
      }
      label::before,
      label::after {
        top: 0;
        width: 1.3rem;
        height: 1.3rem;
        left: -2.2em;
        border-radius: 50%;
      }

      label::before {
        color: $primary;
        border-color: #dedfe0;
        background-color: #dedfe0;
      }
      .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
      }
    }
    .options.privacy {
      input,
      label,
      .customRadio {
        display: inline-block;
      }
      a {
        color: $primary;
        text-decoration: underline;
      }
    }
    .button-wrapper {
      text-align: center;
      .actionButton {
        margin-top: 1em;
        font-size: 1em;
        display: inline-block;
        padding: 0.8em 6em;
      }
      &.notActive {
        .actionButton {
          background: $secondary;
          cursor: default;
        }
      }
    }
  }
}
