.list-highlight {
  margin: 3em 0; }
  .list-highlight .image .image-background {
    height: 600px;
    background-position: center;
    background-size: cover; }
  .list-highlight .elements {
    padding: 1em 2em; }
    .list-highlight .elements h2 {
      /* Wireframe connects t: */
      font-size: 2.3em;
      color: #50554b;
      font-weight: 600;
      letter-spacing: 0; }
    .list-highlight .elements h6 {
      /* Wireframe connects t: */
      font-size: 1.8em;
      color: #50554b;
      letter-spacing: 0;
      line-height: 1;
      margin: 0.5em 0; }
    .list-highlight .elements p {
      /* Far far away, behind: */
      opacity: 0.8;
      font-size: 0.8em;
      color: #50554b;
      letter-spacing: 0;
      line-height: 1.6;
      padding-right: 10%; }
    .list-highlight .elements .element {
      margin-top: 1em; }
