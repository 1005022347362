@import '../../../../globals.scss';
.products-slideshow {
  margin: 0;
  color: $primary;
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(22, 24, 30, 1) 40%,
    rgba(22, 24, 30, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(60%, rgba(255, 255, 255, 1)),
    color-stop(40%, rgba(22, 24, 30, 1)),
    color-stop(100%, rgba(22, 24, 30, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(22, 24, 30, 1) 40%,
    rgba(22, 24, 30, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(22, 24, 30, 1) 40%,
    rgba(22, 24, 30, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(22, 24, 30, 1) 40%,
    rgba(22, 24, 30, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(22, 24, 30, 1) 40%,
    rgba(22, 24, 30, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#16181e', GradientType=1 );

  .actionButton {
    opacity: 1;
    margin: 1em auto;
  }
  // .row {
  //   width: 80%;
  //   margin: 0;
  //   float: right;
  // }

  .sub-title {
    font-size: 1.3em;
    line-height: 1;
    margin: 0.5em 0;
    color: $primary;
    font-weight: 700;
  }

  .grid-element {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.18);
    height: 500px;

    .content-slide {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      .image {
        width: 100%;
      }
      .content {
        align-self: flex-end;
      }
    }
    &:hover {
      box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.18);
    }
    @media (max-width: 1024px) {
      height: 480px;
    }
    @media (max-width: 1024px) {
      height: 480px;
    }
    @media (max-width: 990px) {
      font-size: 0.9em;
      height: 510px;
    }
    @media (max-width: 700px) {
      .pimage {
        height: 180px;
      }
    }
  }

  .slide {
    width: 30%;
    display: inline-block;
  }

  .right {
    display: inline-block;
    width: 100%;
    padding: 3em 0 1em 0;

    .slick-track {
      padding: 0.5em 0;
    }

    .slick-slide {
      opacity: 0.1;
      @media (max-width: 1024px) {
        opacity: 1;
      }
    }
    .slick-active {
      opacity: 1;
    }
    .slick-list {
      overflow-y: visible !important;
      overflow-x: hidden !important;
      text-align: center;
    }
    .slick-active {
      .last {
        opacity: 0.1;
        @media (max-width: 1024px) {
          opacity: 1;
        }
      }
    }

    .arrows {
      display: flex;
      text-align: left !important;
      float: right;
      align-items: center;
      font-size: 3em;
      @media (max-width: 1024px) {
        font-size: 2em;
      }
      color: #fff;
      font-weight: lighter;
      .totals {
        @media (max-width: 1024px) {
          display: none;
        }
      }
      span {
        font-size: 0.4em;
        letter-spacing: 0.2em;
        line-height: 1.6em;
        margin-right: 1em;
        display: inline-block;
      }

      .prev {
        display: inline-block;
        margin-right: 0.5em;
        background: url('../../../assets/arrow_pager.svg') no-repeat center
          center;
        background-size: contain;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);

        &:hover {
          opacity: 1;
        }
      }
      .next {
        display: inline-block;
        cursor: pointer;
        background: url('../../../assets/arrow_pager.svg') no-repeat center
          center;
        background-size: contain;
        width: 20px;
        height: 20px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.section-subtitle {
  text-align: center;
  h3 {
    font-weight: 700 !important;
    color: $primary;
    margin: 2em 0;
    font-size: 2.3em;
  }
}
