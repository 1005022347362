#page_content {
  min-height: 100%;
}

.lang {
  display: none;
}

.loader {
  z-index: 999;
}
