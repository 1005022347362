.lang {
  margin-left: 2em;
  padding-left: 2em !important;
  text-align: left;
  transition: none;

  li {
    margin: 0;
    padding: 0;
    text-align: left;
    display: inline-block;
    &:before {
      color: #90959f;
      content: '• ';
      font-size: 0.8em;
    }
    a {
      color: #90959f;
      font-size: 0.8em !important;
      display: inline-block;
      line-height: 60px;
      text-transform: uppercase;
      text-decoration: none;
      transition: none;

      &.active {
        font-weight: bold;
      }
    }
    &:first-child {
      &:before {
        content: '';
      }
    }
  }
}
