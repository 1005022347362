.section-loading--wrapper {
  position: relative;
  text-align: center;
  height: 60vh;
  .section-loading {
    height: 30vh;
    background: url('../../assets/LoadingWhite.svg') no-repeat center bottom;
    background-size: 20%;
    margin-bottom: 2em;

    @media (max-width: 768px) {
      background-size: 60%;
    }
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    margin: 0px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
