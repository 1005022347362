@import '../../../globals.scss';
.section-nocontent {
  height: 80vh;
  background: url('../../assets/404.png') no-repeat center bottom
    rgba(59, 69, 84, 1);
  background-size: cover;
  // background: rgb(120, 126, 136);
  // background: radial-gradient(
  //   circle,
  //   rgba(120, 126, 136, 1) 0%,
  //   rgba(59, 69, 84, 1) 50%
  // );
  text-align: center;
  .content {
    text-align: center;
    color: #fff;
    margin-top: 10vh;
    strong,
    b {
      font-family: $header-font;
      font-size: 1.7em;
    }
  }
  .actionButton {
    display: inline-block;
    padding-left: 3em;
    padding-right: 3em;
  }
}
