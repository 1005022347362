@import '../../../../globals.scss';
.pager {
  margin: 0 0 1.5em 0;
  .arrows {
    display: flex;
    text-align: left !important;
    float: right;
    align-items: center;
    font-size: 2em;
    color: #fff;
    padding: 0.6em 3em 0.6em 1em;
    background: $primary;
    font-weight: lighter;
    span {
      font-size: 0.6em;
      letter-spacing: 0.2em;
      line-height: 1.6em;
      margin-right: 1em;
      margin-top: 0em;
      display: inline-block;
    }

    .prev {
      display: inline-block;
      margin-right: 0.5em;
      cursor: pointer;
      opacity: 0.7;
      background: url('../../../assets/arrow_pager.svg') no-repeat center center;
      background-size: contain;
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      &:hover {
        opacity: 1;
      }
    }
    .next {
      display: inline-block;
      cursor: pointer;
      background: url('../../../assets/arrow_pager.svg') no-repeat center center;
      background-size: contain;
      width: 20px;
      height: 20px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
