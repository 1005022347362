$primary: #16181e;
$secondary: #d8d8d8;
$menu: #16181e;
$white: #fff;
$footer: #d8d8d8;
$submenu1: #d8d8d8;

$header-font: "Metric-web", Helvetica, Arial, serif;
$body-font: "Metric-web", Helvetica, Arial, sans-serif;
// $body-font: 'Metric-normal', sans-serif;
